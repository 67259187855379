@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&family=Hedvig+Letters+Serif:opsz@12..24&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Reenie+Beanie&family=Roboto+Condensed:wght@200;400;700&family=Shadows+Into+Light&family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');


body, body * {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: 'Roboto Condensed', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6, .font-header {
  font-family: 'Lora', math, serif;
  font-weight: 600;
}

a.link {
  color: rgb(231 70 148 )
}

.firebase-emulator-warning {
  display: none;
}
button.w-full span,
button.w-screen span {
  justify-content: center;
}
[aria-disabled="true"] {
  pointer-events: none;
  opacity: .5;
}
.h-vh {
  height: calc(100vh - 124px);
}

.min-h-vh {
  min-height: calc(100vh - 124px);
}

.hover-parent .hover-child {
  max-height: 0px;
  overflow: hidden;
  transition: .25s;
}
.hover-parent:hover .hover-child {
  max-height: 200px;
}

.rotate {
  animation: rotate .5s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
